import {
  EditorReadyFn,
  GetAppManifestFn,
  HandleActionFn,
  OnEventFn,
} from '@wix/yoshi-flow-editor';

import { createAppApi } from './appApi';
import {
  ActionType,
  ControllerType,
  EditorAppContext,
  EventType,
} from './types';
import { Interaction } from './constants/interaction';
import { doFirstInstall } from './doFirstInstall';
import { getAllSearchBoxes } from './searchBox';
import { reconnectSearchBoxes } from './reconnectSearchBoxes';
import { patchInputFontProperty } from './patchInputFontProperty';
import { onRemoveApp } from './onRemoveApp';
import { onComponentAddedToStage } from './onComponentAddedToStage';
import { configureAppManager } from './appManager';
import { reportError } from '../lib/errors';

interface EditorPlatformApp {
  editorReady: EditorReadyFn;
  getAppManifest: GetAppManifestFn;
  handleAction: HandleActionFn;
  onEvent: OnEventFn;
}

export function createEditorPlatformApp(): EditorPlatformApp {
  let appContext: EditorAppContext;

  return {
    async editorReady(editorSDK, appDefinitionId, options, flowAPI) {
      const { firstInstall } = options;
      const {
        experiments,
        fedops,
        errorMonitor,
        environment,
        translations,
      } = flowAPI;
      const { isEditorX } = environment;

      fedops.interactionStarted(Interaction.EditorReady);

      appContext = {
        flowAPI,
        translate: translations.t.bind(translations),
        editorSDK,
        appDefinitionId,
        fedops,
        experiments,
        isEditorX: environment.isEditorX,
        reportError(error) {
          reportError(errorMonitor, error, {
            firstInstall,
            isEditorX,
          });
        },
      };

      // NOTE: Yoshi has its own setAppAPI() attached for Editor undo/redo history functionality.
      // Merging here both APIs as suggested by Yoshi team.
      // Revisit this when this issue is fixed: https://github.com/wix-private/yoshi/issues/6584
      await editorSDK.editor.setAppAPI(appDefinitionId, {
        ...(await editorSDK.editor.getAppAPI()),
        ...createAppApi(appContext),
      });

      if (firstInstall) {
        await doFirstInstall(appContext);
      }

      const allSearchBoxes = await getAllSearchBoxes(appContext);

      if (!isEditorX) {
        /**
         * This is a hacky way to reconnect 'abandoned' SearchBox'es (probably added by copy-pasting).
         * Investigate if it's still really needed.
         * https://jira.wixpress.com/browse/SER-1310
         */
        await reconnectSearchBoxes(appContext, allSearchBoxes);
      }

      // TODO Should we run this part of code, if none of components registered? i.e. allSearchBoxes.length === 0
      await Promise.all(
        allSearchBoxes.map((sb) => patchInputFontProperty(appContext, sb)),
      );
      await editorSDK.document.application.registerToCustomEvents(
        appDefinitionId,
        {
          eventTypes: [EventType.ComponentAddedToStage],
        },
      );
      fedops.interactionEnded(Interaction.EditorReady);
    },
    async getAppManifest({ appManifestBuilder }) {
      return appManifestBuilder
        .configureController(ControllerType.SearchApp, (controllerBuilder) => {
          controllerBuilder.set({
            visibility: 'NEVER',
          });
        })
        .configureManagementActions((managementActionsBuilder) => {
          if (appContext.experiments.enabled('specs.siteSearch.AppManager')) {
            configureAppManager(appContext, managementActionsBuilder);
          }
        })
        .build();
    },
    async handleAction({ type }) {
      switch (type) {
        case ActionType.RemoveApp: {
          await onRemoveApp(appContext);
          break;
        }
      }
    },
    async onEvent({ eventType, eventPayload }) {
      switch (eventType) {
        case EventType.ComponentAddedToStage: {
          await onComponentAddedToStage(appContext, eventPayload);
          break;
        }
      }
    },
  };
}
