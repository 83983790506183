import { createSettingsClient, AppSettings } from '@wix/search-settings-client';

import { EditorAppContext } from './types';

export type AppApi = {
  getSavedSettings(): Promise<AppSettings>;
  saveSettings<T extends keyof AppSettings>(
    key: T,
    value: AppSettings[T],
  ): Promise<void>;
};

export const createAppApi = ({ flowAPI }: EditorAppContext): AppApi => {
  const { httpClient } = flowAPI;
  const settingsClient = createSettingsClient({
    httpClient,
  });

  return {
    getSavedSettings() {
      return settingsClient.getSaved();
    },
    saveSettings(key, value) {
      return settingsClient.set(key, value);
    },
  };
};
